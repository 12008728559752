const version = "/v1";
const ats = "/ats";

export const rowsPerPage = 15;

const job = ats + version + "/job";
export const jobApiEndpoint = {
  jobDescription: ats + version + "/job_description/section",
  jobTypes: job + "/type",
  jobShift: job + "/shift",
  jobCategories: job + "/category",
  workModeLocation: job + "/work_location",
  companyBranches: ats + version + "/company/branch",
  job: job,
};

const round = ats + version + "/round";
export const roundApiEndpoint = {
  roundWorkflow: round + "/workflow",
  roundType: round + "/type",
  roundWorkflowConfig: round + "/workflow_configuration",
};

export const candidateApiEndpoint = {
  candidate: ats + version + "/candidate",
  candidateApplication: ats + version + "/candidate/application",
  uploadResumes: ats + version + "/candidate/upload_resumes",
};

export const commonApiEndpoint = {
  currency: version + "/currency?limit=200",
  country: version + "/country",
  state: version + "/state",
  city: version + "/city",
};

export const interviewApiEndpoint = {
  allInterviews: ats + version + "/interview/all_interviews",
  uploadResumes: ats + version + "/candidate/upload_resumes",
  myInterviews: ats + version + "/interview/my_interviews",
  interviewRoundCand: ats + version + "/interview/round/candidates",
  screeningInterview:
    ats + version + "/interview/screening_interview_chat_history/",
  roundCandidateApplication:
    ats + version + "/interview/round/candidate_application",
  interViewRoundFeedback: ats + version + "/interview_feedback/feedbacks",
};
const user = ats + version + "/user";
export const userAPIEndpoint = {
  user: user + "/me",
  companySubscription: "/subscription/v1/subscription/company/me",
};
