import {
  FC,
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useEffect,
  useState,
  useRef,
} from "react";
import { jwtDecode } from "jwt-decode";
import * as authHelper from "../helpers/AuthHelpers";
import { useAuth0 } from "@auth0/auth0-react";
import { extractLoggedUserRole, getCookie, setCookie } from "helpers/common";
import apiInterceptor from "services/apiInterceptor";
import { userAPIEndpoint } from "constant/APIConstant";
const { REACT_APP_DOMAIN } = process.env;
const domain: string = REACT_APP_DOMAIN ?? "";
type WithChildren = {
  children?: ReactNode;
};
type AuthContextProps = {
  systemLogout: () => void;
  loggedUserRole: any;
  loggedUserRoleSetter: (jsonData: any) => void;
  fetchUserDetail: any;
  fetchSubcriptionDetail: any;
  userDetails: any;
  setUserDetails: any;
  companySubscriptionDetails: any;
};
const initAuthContextPropsState = {
  systemLogout: () => {},
  loggedUserRole: {},
  loggedUserRoleSetter: (jsonData: any) => {},
  fetchUserDetail: () => {},
  fetchSubcriptionDetail: () => {},
  companySubscriptionDetails: {},
  userDetails: undefined,
  setUserDetails: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const { logout } = useAuth0();
  const [loggedUserRole, setLoggedUserRole] = useState<any>({});
  const [userDetails, setUserDetails] = useState<any>();
  const [companySubscriptionDetails, setCompanySubscriptionDetails] =
    useState<any>();

  const systemLogout = () => {
    authHelper.removeAuth();
    setCookie("isLogout", "true", domain);
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const loggedUserRoleSetter = (jsonData: any) => {
    setLoggedUserRole(jsonData);
  };
  const fetchUserDetail = async () => {
    try {
      await apiInterceptor
        .get(userAPIEndpoint.user)
        .then((response: any) => {
          setUserDetails(response.data.user);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };
  const fetchSubcriptionDetail = async () => {
    try {
      await apiInterceptor
        .get(userAPIEndpoint.companySubscription)
        .then((response: any) => {
          if (response.data.status === "ERROR") {
            setCompanySubscriptionDetails({
              isExpired: true,
            });
          } else {
            setCompanySubscriptionDetails({
              ...response.data.data,
              isExpired: response.data.data.isExpired === "Y",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };
  const contextValue = useMemo(
    () => ({
      systemLogout,
      loggedUserRole,
      setLoggedUserRole,
      loggedUserRoleSetter,
      userDetails,
      fetchUserDetail,
      setUserDetails,
      fetchSubcriptionDetail,
      companySubscriptionDetails,
    }),
    // eslint-disable-next-line
    [loggedUserRole, userDetails, setUserDetails]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const {
    isAuthenticated,
    getIdTokenClaims,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();
  const {
    loggedUserRoleSetter,
    systemLogout,
    fetchSubcriptionDetail,
    fetchUserDetail,
  } = useAuth();

  const didRequest = useRef(false);
  const [isStoredToken, setIsStoredToken] = useState(false);

  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          if (apiToken) {
            fetchUserDetail();
            fetchSubcriptionDetail();
          } else {
            //  will add some logic
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          systemLogout();
        }
      }
      return () => (didRequest.current = true);
    };

    if (authHelper.getATSAccessToken().length > 0 && isStoredToken) {
      requestUser(authHelper.getATSAccessToken());
    }
    // eslint-disable-next-line
  }, [isStoredToken]);

  useEffect(() => {
    (async function () {
      if (isAuthenticated) {
        try {
          setCookie("isLogout", "false", domain);
          if (getCookie("isLogout") === "true") {
            console.log("logout");
            loginWithRedirect();
          }
          const claims = await getIdTokenClaims();
          const accessToken = await getAccessTokenSilently();
          const decodedToken = jwtDecode<any>(accessToken);
          const userData = {
            user_email: decodedToken.user_email,
            user_guid: decodedToken.user_guid,
            user_role: extractLoggedUserRole(decodedToken.user_role),
          };
          loggedUserRoleSetter(userData);
          authHelper.setATSAccessToken(accessToken);
          const cvalue: string = claims?.["org_id"] ?? "";
          setCookie("udo-oi-amdmdx", cvalue);
          setIsStoredToken(true);
        } catch (error: any) {
          loginWithRedirect();
        }
      }
    })();
    // eslint-disable-next-line
  }, [
    isAuthenticated,
    getIdTokenClaims,
    loginWithRedirect,
    getAccessTokenSilently,
  ]);

  return <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
